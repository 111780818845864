<template>
  <div style="display: inline-block;">
    <div class="participant-list">
      <template v-if="storageParticipants.length">
        <Chip @click.prevent.stop="display = !display"
              v-for="participant in storageParticipants"
              :key="participant.id"
        >
          {{ participant.fullName }}
        </Chip>
      </template>
      <template v-else>
        <a href="#"
           @click.prevent.stop="display = !display; $store.commit('participant/resetPage')">{{
            _t('filter_participant_filter')
          }}</a>
      </template>
    </div>
    <Dialog v-model:visible="display" :closable="false" :modal="true" :draggable="false"
            :style="{ width: '450px', height: 'auto', maxHeight: '550px' }">
      <template #header>
        <div class="p-d-flex p-flex-column p-ml-0 p-pl-3" style="width: 100%">
          <div class="p-inline-flex">
            <h3 class="p-mb-0">{{ _t('filter_participant_filter') }}</h3>
          </div>
          <MultiSelectableBreadcrumb ref="breadcrumbData"></MultiSelectableBreadcrumb>
        </div>
      </template>
      <Listbox
          v-model="selected"
          :options="participantList"
          optionLabel="fullName"
          optionValue="id"
          :multiple="multiple"
          :metaKeySelection="false"
          dataKey="id"
          @change="$store.dispatch('participant/toggleParticipant', multiple ? $event.value : [$event.value])"
      >
        <template #header>
          <div class="p-inline-flex">
            <div class="p-field p-mt-0 p-mb-2">
                <span class="p-input-icon-left" style="width: 100%">
                  <i class="pi pi-search"/>
                  <InputText
                      type="text"
                      v-model:value="search"
                      :placeholder="_t('label_search')"
                      class="p-pr-1"
                      style="width: 100%"
                      @keyup="$store.dispatch('participant/searchParticipants', $event.target.value)"
                  />
                </span>
            </div>
          </div>
        </template>
        <template #option="slotProps">
          <div>
            {{ slotProps.option.surname }} {{ slotProps.option.name }}<span
              v-if="slotProps.option.login"> ({{ slotProps.option.login }})</span>
          </div>
        </template>
      </Listbox>
      <template #footer>
        <Paginator
            :rows="rows"
            :totalRecords="totalItemsCount"
            @page="$store.dispatch('participant/setPage', $event.page)"
        />
        <Button :label="_t('Clear')" icon="pi pi-times" class="p-button-text"
                @click="$store.dispatch('participant/clearSelected'); $emit('change', selected); storageParticipants = []; display = false"/>
        <Button :label="_t('Select')" icon="pi pi-check" autofocus
                @click="$emit('change', selected); display = false; setSelectedParticipantsToStorage()"/>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import MultiSelectableBreadcrumb from "./ixarma/MultiSelectableBreadcrumb";

export default {
  name: 'select-participant',
  components: {
    MultiSelectableBreadcrumb
  },
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    participantsData: {
      type: Array,
    },
    activeTab: {
      type: Number,
      default: null,
    },
    filtersPrefix: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    display: false,
    orgId: localStorage.getItem('orgId'),
    storageParticipants: [],
  }),
  mounted() {
    this.checkOrgId()

    const participants = JSON.parse(localStorage.getItem('af.' + this.filtersPrefix + '.participantsFilter'))

    if (participants) {
      this.storageParticipants = participants
      this.setSelectedParticipants(participants.map((el) => el.id))
      this.selected.push(...participants.map((el) => el.id))
      this.selected.shift()
    }
  },
  beforeUnmount() {
    clearInterval(parseInt(this.checkOrgIdIntervalId))
  },
  watch: {
    display() {
      if (this.display) {
        this.fetchParticipants();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchParticipants: 'participant/getParticipants',
      setSelectedParticipants: 'participant/setSelectedParticipants',
    }),
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
          this.fetchParticipants()
        }
      }, 100);
    },
    setSelectedParticipantsToStorage() {
      const participants = JSON.parse(localStorage.getItem('af.' + this.filtersPrefix + '.participantsFilter')) ?? []
      this.storageParticipants = participants
    }
  },
  computed: {
    ...mapGetters({
      getLoading: 'participant/getLoading',
      participantList: 'participant/getParticipants',
      rows: 'participant/getPageSize',
      totalItemsCount: 'participant/getCount',
      search: 'participant/getSearch',
      selectedParticipants: 'participant/getSelectedParticipants',
    }),
    selected: {
      get: function () {
        if (this.multiple) {
          return this.selectedParticipants.map(i => i.id);
        } else {
          return this.selectedParticipants.map(i => i.id)[0] || null;
        }
      },
      set: function (value) {
        if (this.multiple) {
          [...new Set(value)].map(id => {
            if (!id) return;
            const index = this.selected.findIndex(i => i === id);
            if (index !== -1) {
              this.selected.splice(index, 1);
            } else {
              this.selected.push(id);
            }
            this.selected = [...new Set(this.selected)];
            this.setSelectedParticipants([...new Set(this.selected)])
          });
        }
      },
    },
  },
}
</script>

<style scoped>
:deep(.participant-list a) {
  border-right: 2px solid #82B541;
  font-size: 0.858rem;
  padding: 0 5px;
  margin: 0;
}

:deep(.participant-list a:last-child) {
  border-right: none;
}

:deep(.p-dialog-header-icons) {
  margin-top: -20px;
}
</style>
