<template>
  <div class="p-grid">
    <div class="p-col-12 p-field p-mb-0 p-grid p-ai-start">
      <label class="p-col-fixed p-mt-2" for="org" style="width:150px">{{ _t('label_orgs') }}</label>
      <div class="p-col">
        <MultiSelectableBreadcrumb :singleSelection="false" @change="setOrgId"></MultiSelectableBreadcrumb>
      </div>
    </div>
    <div class="p-lg-3 p-md-6 p-sm-12 p-field p-grid p-mb-0 p-ai-start">
      <label class="p-col-fixed p-mt-2" for="title" style="width:100px">{{ _t('specify_title') }}</label>
      <div class="p-col">
        <InputText id="title" v-model="title" :placeholder="_t('optional_title')" autocomplete="off"/>
      </div>
    </div>
    <div class="p-lg-3 p-md-6 p-sm-12 p-field p-grid p-mb-0 p-ai-start">
      <label class="p-col-fixed p-mt-2" for="status" style="width:100px">{{ _t('label_status') }}</label>
      <div class="p-col">
        <MultiSelect
            id="status"
            v-model="statuses"
            :options="alarmJobStatuses"
            :placeholder="_t('filter_statuses_filter')"
            :showClear="true"
            class="p-inputtext p-p-0 filter-input"
            display="chip"
            style="width: 11rem;"
        />
      </div>
    </div>
    <div class="p-lg-3 p-md-6 p-sm-12 p-field p-grid p-mb-0 p-ai-start">
      <label class="p-col-fixed p-mt-2" for="name" style="width:100px">{{ _t('label_Alarm_name') }}</label>
      <div class="p-col">
        <InputText id="name" v-model="name" :placeholder="_t('label_search_by_name')" class="filter-input"/>
      </div>
    </div>
    <div class="p-lg-3 p-md-6 p-sm-12 p-field p-grid p-mb-0 p-ai-start">
      <label class="p-col-fixed p-mt-2" for="alarmPointId" style="width:100px">{{ _t('label_alarm_id') }}</label>
      <div class="p-col">
        <InputText id="alarmPointId" v-model="alarmPointId" :placeholder="_t('label_search_by_alarm_point_ID')"
                   class="filter-input"/>
      </div>
    </div>
    <div class="p-lg-3 p-md-6 p-sm-12 p-field p-grid p-mb-0 p-ai-start">
      <label class="p-col-fixed p-mt-2" for="systemId" style="width:100px">{{ _t('label_system_id') }}</label>
      <div class="p-col">
        <InputText id="systemId" v-model="systemId" :placeholder="_t('label_search_by_system_ID')"
                   class="filter-input"/>
      </div>
    </div>
    <div class="p-lg-3 p-md-6 p-sm-12 p-field p-grid p-mb-0 p-ai-start">
      <label class="p-col-fixed p-mt-2" style="width:100px">{{ _t('label_started') }}</label>
      <div class="p-col">
        <ExtendedCalendar v-model:date="startedAt" class="filter-input" orientation="left"/>
      </div>
    </div>
    <div class="p-lg-3 p-md-6 p-sm-12-3 p-field p-grid p-mb-0 p-ai-start">
      <label class="p-col-fixed p-mt-2" for="lastHours" style="width:100px">{{ _t('label_Last_hours') }}</label>
      <div class="p-col">
        <InputNumber id="lastHours" v-model="lastHours" :min="0" :placeholder="_t('label_Last_hours')"
                     class="filter-input"/>
      </div>
    </div>
    <div class="p-lg-3 p-md-6 p-sm-12 p-field p-grid p-mb-0 p-ai-start">
      <label class="p-col-fixed p-mt-2" for="triggeredBy" style="width:100px">{{ _t('filter_triggeredby') }}</label>
      <div class="p-col">
        <InputText id="triggeredBy" v-model="triggeredBy" :placeholder="_t('filter_triggeredby')" class="filter-input"/>
      </div>
    </div>
    <div class="p-col-12 p-field p-grid p-mb-0 p-ai-start">
      <label class="p-col-fixed p-mt-2" for="participants" style="width:100px">{{ _t('PARTICIPANTS') }}</label>
      <div class="p-col">
        <SelectParticipant filters-prefix="detailed_reports" :multiple="true"
                           id="participants" @change="setParticipant"/>
      </div>
    </div>
  </div>
  <Button :label="_t('label_Preview')" :loading="loading" class="p-button-success p-mr-2" @click="preview"/>
  <Button :loading="loading" class="p-button-success p-mr-2" icon="pi pi-file-excel"
          label="XLS" @click="download('xls')"/>
  <Button :loading="loading" class="p-button-success p-mr-2" icon="pi pi-file-pdf"
          label="PDF" @click="download('pdf')"/>

  <Fieldset v-if="previewData.list.length" class="p-mt-4" legend="Preview">
    <DataTable
        :value="previewData.list"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        :page="currentPage"
        :lazy="true"
        :totalRecords="previewData.count"
        :rows="previewTableRows"
        :paginator="true"
        :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink'"
        @page="selectTablePage($event)"
    >
      <template #paginatorstart>
        <div class="p-inputgroup" style="padding-top:10px;">
          <p class="p-inputgroup-addon" style="padding: 5px; font-size: 1rem;">
            {{ _t('primefaces.paginator.aria.ROWS_PER_PAGE') }}</p>
          <Dropdown v-model="previewTableRows"
                    :options="pageSize"
                    style="margin: 0px;"
          >
          </Dropdown>
        </div>
      </template>
      <Column :header="_t('label_status')" field="alarmJobStatus">
        <template #body="{ data }">
          <div class="p-d-flex">
            <div class="p-d-inline-flex p-mr-2" style="max-width:2rem;">
              <Button
                  :style="'background-color: ' + associateColor(data.jobStatusColor)"
                  style="max-height: 20px; right:20px; border: none; border-radius: 5px; margin:2px; display: inline-block;"
                  type="button"
              />
            </div>
            <div class="p-d-inline-flex p-mt-1">
              <strong class="truncate" style="max-width: 12rem;">
                {{ data.alarmJobStatus }}
              </strong>
            </div>
          </div>
        </template>
      </Column>
      <Column field="alarmPointStatus" header=""></Column>
      <Column :header="_t('label_Alarm_name')" field="alarmPointName"></Column>
      <Column :header="_t('filter_triggeredby')" field="triggeredBy"></Column>
      <Column :header="_t('label_alarm_id')" field="alarmPointId"></Column>
      <Column :header="_t('label_started')" field="alarmJobStartDate"></Column>
      <Column field="alarmJobEndDate" :header="_t('label_going')"></Column>
      <Column :header="_t('label_system_id')" field="alarmPointSysId"></Column>
    </DataTable>
  </Fieldset>
  <Toast/>
</template>

<script>
import MultiSelectableBreadcrumb from "@/components/ixarma/MultiSelectableBreadcrumb";
import SelectParticipant from "@/components/SelectParticipant";
import ExtendedCalendar from '@/components/ExtendedCalendar';
import {mapGetters} from "vuex";
import {getDetailedReport} from "../../api/reports";
import {getHistory} from "../../api/alarmTable";
import {associateColor} from "@/service/helper/styleHelper";
import moment from "moment";

export default {
  name: 'report-detailed',
  components: {
    MultiSelectableBreadcrumb,
    SelectParticipant,
    ExtendedCalendar
  },
  data: () => ({
    orgIds: localStorage.getItem('multiOrgIds') ?? [{id: 1}],
    statuses: null,
    name: '',
    alarmPointId: "",
    systemId: null,
    startedAt: null,
    lastHours: 23,
    triggeredBy: null,
    participants: [],
    loading: false,
    alarmJobStatuses: ['NEW', 'CONFIG_PROBLEM', 'INACTIVE', 'FAILURE', 'FINISHED', 'INTERRUPTED',
      'CANCELED', 'NOT_ACCEPTED', 'NOT_ACKNOWLEDGED', 'PARTIAL_ACCEPTED', 'ACCEPTED',
      'PROCESSING', 'PARTIAL_ACKNOWLEDGED', 'ACKNOWLEDGED', 'SCHEDULED'
    ],
    title: '',
    previewData: {
      count: 0,
      currentPage: 0,
      list: [],
      pagesCount: 1,
    },
    currentPage: 0,
    previewTableRows: 20,
    pageSize: [5, 10, 20, 50],
  }),
  watch: {
    previewTableRows() {
      this.currentPage = 0
      this.preview()
    },
  },
  methods: {
    associateColor,
    setOrgId: function (id) {
      this.orgIds = id
    },
    setParticipant() {
      this.participants = this.selectedParticipants
      localStorage.setItem('af.detailed_reports.participantsFilter', JSON.stringify(this.selectedParticipants))
    },
    buildQuery() {
      let duration =
          {
            start: this.startedAt?.start ? moment(this.startedAt.start).toDate() : null,
            end: this.startedAt?.end ? moment(this.startedAt.end).toDate() : null,
            option: this.startedAt?.start ? 'RANGE' : 'HOURS_DURATION',
            hours: this.lastHours
          }
      return {
        columnsFilter: {
          alarmName: this.name,
          alarmPointId: this.alarmPointId,
          statuses: this.statuses,
          systemId: this.systemId,
          triggeredBy: this.triggeredBy
        },
        currentPage: 0,
        duration: duration,
        name: this.title,
        orderByPriority: true,
        organizationFilter: JSON.parse(localStorage.getItem('multiOrgIds')),
        previewTableRows: 20,
        selectChildOrgs: localStorage.getItem('withChildOrgs') === 'true',
        participantsFilter: this.participants,
      };
    },
    selectTablePage(item) {
      this.loading = true
      this.currentPage = item.page
      this.preview();
    },
    preview() {
      const query = this.buildQuery();
      this.loading = true;
      return getHistory(
          this.currentPage,
          '',
          null,
          this.previewTableRows,
          query.columnsFilter,
          query.organizationFilter,
          query.duration,
          false,
          query.participantsFilter
      )
          .then(
              res => {
                if (res.status === 200) {
                  this.previewData = res.data;
                  this.loading = false;
                  return Promise.resolve(res.data)
                }
                return Promise.reject('Something went wrong');
              },
              error => {
                let data = error.response?.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: (data.reason ? ': ' + data.reason : 'Something went wrong'),
                  life: 3000
                });
              },
          )
          .finally(() => {
            this.loading = false;
          })
    },
    download(type = 'xls') {
      this.loading = true;
      return getDetailedReport(type, this.buildQuery())
          .then(
              res => {
                if (res.status === 200) {
                  var a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  const blob = new Blob([res.data], {type: 'xlsx'});
                  var url = window.URL.createObjectURL(blob);
                  a.href = url;
                  let headerLine = res.headers['content-disposition']
                  a.download = `${Date.now()}.${type}`
                  if (headerLine) {
                    a.download = headerLine.split('=').pop().replace('"', '')
                  }
                  a.click();
                  window.URL.revokeObjectURL(url);
                  this.loading = false;
                  return Promise.resolve();
                }
                return Promise.reject('Something went wrong');
              },
              error => {
                let data = error.response?.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: (data.reason ? ': ' + data.reason : 'Something went wrong'),
                  life: 3000
                });
              }
          )
          .finally(() => {
            this.loading = false;
          })
    }
  },
  computed: {
    ...mapGetters({
      selectedParticipants: 'participant/getSelectedParticipants',
    }),
  },
}
</script>
